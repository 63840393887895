import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import BgImage from "../../images/celebrate-recovery.jpg"
import Layout from "../../components/layoutPhoenixCustom"
import PageHeaders from "../../components/pageHeaders"
import SEO from "../../components/seo"

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
`

const IndexPage = () => (
  <Layout
    ImgSrc={BgImage}
    MainText="Celebrate Recovery"
    SecondText="Freedom from hurts, habits, and hangups."
  >
    <SEO title="Celebrate Recovery" />
    <PageHeaders
      css={css`
        display: flex;
      `}
    >
      Best Place to be On A Wednesday Night
    </PageHeaders>
    <HeaderText>Where?</HeaderText>
    <ParagraphText>
      Cross Church Phoenix.
      <br />
      1827 W. Grovers Ave.
      <br />
      Phoenix, AZ 85023
      <br />
    </ParagraphText>
    <HeaderText>When?</HeaderText>
    <ParagraphText>6:30pm</ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      What is Celebrate Recovery?
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      Celebrate Recovery is a Christ-centered 12-step program that assists
      people with a wide variety of life-related issues. If you are dealing with
      painful hurts in your life, struggling with addictive habits, or not able
      to get over your personal hang-ups, then Celebrate Recovery is the place
      for you.
    </ParagraphText>
  </Layout>
)

export default IndexPage
